import React from 'react';
import PropTypes from 'prop-types';
import * as routes from 'lib/routes';
import participantImage from 'images/legacy-sign-in-participant.png';
import researcherImage from 'images/legacy-sign-in-researcher.png';

// eslint-disable-next-line local-rules/css_modules
import * as styles from './link_button.module.scss';

export const AuthenticationType = {
  Researcher: 'Researcher',
  Participant: 'Participant',
};

function getLinkButtonAttributes({ authenticationType, isSignin }) {
  if (!(authenticationType in AuthenticationType)) {
    throw new Error(`invalid authentication type: ${authenticationType}`);
  }

  const { img, signInPath, signupPath, title } = {
    [AuthenticationType.Participant]: {
      img: participantImage,
      signInPath: routes.participant_signin_path(),
      signupPath: routes.participant_signup_path(),
      title: 'Participant',
    },
    [AuthenticationType.Researcher]: {
      img: researcherImage,
      signInPath: routes.account_signin_path(),
      signupPath: routes.account_signup_path(),
      title: 'Researcher',
    },
  }[authenticationType];

  return {
    img,
    title,
    preText: isSignin ? 'Sign in as a' : 'Sign up as a',
    path: isSignin ? signInPath : signupPath,
  };
}

export function LinkButton({ authenticationType, isSignin }) {
  const { img, path, preText, title } = getLinkButtonAttributes({
    authenticationType,
    isSignin,
  });

  return (
    <a className={styles.linkButton} href={path}>
      <img alt="" src={img} />
      <p className={styles.preText}>{preText}</p>
      <p className={styles.title}>{title}</p>
    </a>
  );
}

LinkButton.propTypes = {
  authenticationType: PropTypes.oneOf(Object.values(AuthenticationType))
    .isRequired,
  isSignin: PropTypes.bool.isRequired,
};
