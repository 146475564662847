import React from 'react';

import { Provider } from 'react-redux';
import store from 'store/store';

export const withReduxStore = (WrappedComponent) =>
  function (props) {
    return (
      <Provider store={store}>
        <WrappedComponent {...props} />
      </Provider>
    );
  };
